import classNames from 'classnames';
import React from 'react';

const Message = ({ item, isLast }) => {
  const { msg, reply, filename, icon, wait } = item;
  if (wait) {
    return (
      <div
        className={classNames('py-4', {
          'opacity-0': !isLast
        })}
      >
        <div className="animate-pulse opacity-0 w-4 h-1 bg-green-500 rounded-full" />
      </div>
    );
  }
  if (filename && icon) {
    return (
      <div
        className={`flex ${!reply ? 'justify-end' : 'justify-start'} rmb-2 `}
      >
        <div className="panel-bg panel-border rounded-lg flex  gap-x-2 items-center fond-medium py-2 px-3">
          <span className="w-7 h-7">{icon}</span> {filename}
        </div>
      </div>
    );
  }
  return (
    <div className={`flex ${!reply ? 'justify-end' : 'justify-start'} mb-2`}>
      <div
        className={`p-2 px-3 rounded-lg ${!reply ? ' bg-gradient-to-b from-blue-500 to-blue-600  text-white' : ' bg-gradient-to-b dark:bg-gradient-to-b from-neutral-100 to-neutral-200 text-black dark:from-neutral-800 dark:to-neutral-900 dark:text-white'} max-w-xs`}
      >
        {msg}
      </div>
    </div>
  );
};

export default Message;
