import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { spring, presets, TransitionMotion } from 'react-motion';
import { CalendarClock, CheckCircle2, AlertTriangle } from 'lucide-react';

export const questions = [
  {
    text: '',
    icon: 'ExpansionIcon',
    schedule: 'Quarterly',
    textClasses: 'text-blue-600',
    classNames: 'bg-gradient-to-tr from-blue-500 to-green-600',
    entries: [
      {
        header: 'Your Weekly News Summary',
        text: "Here's your curated news summary for this week, covering top stories from tech to global events.",
        icon: CalendarClock,
        color: 'text-blue-500'
      },
      {
        header: 'Merger Rumors',
        text: 'One of your holdings is rumored to be in talks for a merger or acquisition.',
        icon: CheckCircle2,
        color: 'text-green-500'
      },
      {
        header: 'Fraud Alert',
        text: 'One of your holdings had media reports about possible fraud.',
        icon: AlertTriangle,
        color: 'text-yellow-500'
      },
      {
        header: 'Key Personnel Change',
        text: 'One of your holdings had a key personnel change.',
        icon: AlertTriangle,
        color: 'text-yellow-500'
      }
    ]
  }
];

const windowSize = 1;

export default function ResearchHeader() {
  const [stack, setStack] = useState<any[]>([]);
  const [headerItemIndex, setHeaderItemIndex] = useState(0);
  const [currentContent, setCurrentContent] = useState('');
  const [charIndex, setCharIndex] = useState(0);
  const [typingDone, setTypingDone] = useState(false);
  const [startWaiting, setStartWaiting] = useState(false);
  const refs = Array.from({ length: 5 }, () => useRef<HTMLLIElement>(null));

  const stackRef = useRef(stack);
  stackRef.current = stack;
  const headerItemRef = useRef(headerItemIndex);
  headerItemRef.current = headerItemIndex;

  useEffect(() => {
    let timeoutChange;
    const interval = setInterval(
      () => {
        if (
          stackRef.current.length <
          questions[headerItemRef.current].entries.length
        ) {
          setStack((prevStack) => {
            const nextIndex = prevStack.length;
            if (nextIndex < questions[headerItemRef.current].entries.length) {
              return [
                questions[headerItemRef.current]?.entries[nextIndex],
                ...prevStack
              ];
            }
            return prevStack;
          });
        } else {
          timeoutChange = setTimeout(
            () => {
              setHeaderItemIndex((prevIndex) => {
                const nextIndex = (prevIndex + 1) % questions.length;
                return nextIndex;
              });
              setStack([]);
              setCharIndex(0);
              setTypingDone(false);
              setCurrentContent('');
            },
            Math.random() * 1400 + 1000
          );
        }
      },
      Math.random() * 1400 + 3000
    );
    return () => {
      clearTimeout(timeoutChange);
      clearInterval(interval);
    };
  }, []);

  const getStyles = () => {
    return stack.map((d, i) => {
      const offset = refs
        .slice(0, i)
        .reduce((a, c) => a + (c?.current?.offsetHeight || 0), 0);
      return {
        key: `spin-it-${d?.text}`,
        data: d,
        style: {
          opacity: spring(1 - i * (1 / 3), presets.gentle),
          translateY: spring(offset, presets.gentle),
          scale: spring(1, presets.gentle)
        }
      };
    });
  };

  const willEnter = () => {
    return {
      opacity: 1,
      scale: 0.1,
      translateY: 0
    };
  };

  const willLeave = () => {
    return {
      opacity: 0,
      scale: 0,
      translateY: 0
    };
  };

  const allMshIds = questions.reduce((acc, val) => {
    return acc.concat((val.entries as any).map((d) => d.msh_id));
  }, []);

  return (
    <div className="w-full max-w-xl mx-auto h-[16rem] ">
      <div className="gap-x-10 gap-y-3 w-full flex items-center justify-center h-full">
        <div
          className={classNames(
            `gap-10 relative w-full p-5  h-full ${typingDone ? 'slide-in' : ''}`
          )}
        >
          <TransitionMotion
            styles={getStyles()}
            willLeave={willLeave}
            willEnter={willEnter}
          >
            {(styles) => {
              return (
                <ul className="todo-list relative overflow-hidden p-5 h-full ">
                  {styles.map(({ key, style, data }, i) => (
                    <li
                      key={key}
                      ref={refs[i]}
                      style={{
                        transform: `translateY(${style.translateY}px) scale(${style.scale})`,
                        opacity: style.opacity
                      }}
                      className="absolute overflow-hidden w-[90%] pl-2 py-1 max-w-lg mx-auto "
                    >
                      <div className="p-3 rounded-lg panel-bg border border-line text-lg shadow-sm justify-center flex items-center gap-x-3">
                        <div className="">
                          <data.icon
                            className={classNames('w-6 h-6', data.color)}
                            strokeWidth={2}
                            size={10}
                          />
                        </div>
                        <div className="leading-tight">
                          <h3 className="font-semibold text-sm">
                            {data.header}
                          </h3>
                          <p className="text-sm leading-tight ">{data.text}</p>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              );
            }}
          </TransitionMotion>
        </div>
      </div>
    </div>
  );
}
