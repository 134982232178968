import LandingLayout from '@/components/LandingLayout';
import FooterCta from '@/components/ui/PageBlocks/Landing/FooterCta';
import ChatBenefits from '@/components/ui/PageBlocks/Landing/Home/ChatBenefits';
import MainFeatures from '@/components/ui/PageBlocks/Landing/Home/MainFeatures';
import SalesHero from '@/components/ui/PageBlocks/Landing/Home/SalesHero';
import Subhead from '@/components/ui/PageBlocks/Landing/Home/Subhead';
import ResearchFaq from '@/components/ui/PageBlocks/Marketing/ResearchFaq';
// import SocialProof from '@/components/ui/PageBlocks/Landing/Home/SocialProof';
import { getActiveProductsWithPrices } from '@/utils/supabase-client';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { ReactElement, useEffect, useRef } from 'react';
import { Gradient } from 'stripe-gradient';

const GlobeComponent = dynamic(
  () => import('@/components/ui/PageBlocks/Landing/Globe'), // replace with the path to your Globe component
  { ssr: false } // This line is important. It tells Next.js to only render the component on the client side
);

export const getStaticProps = async () => {
  const products = await getActiveProductsWithPrices();

  return {
    props: {
      products: products
    }
  };
};
export function New({ products }) {
  const planeDevRef = useRef(null);
  useEffect(() => {
    new Gradient({
      canvas: '#my-canvas-id',
      colors: ['#a960ee', '#ff333d', '#90e0ff', '#ffcb57']
    });
  }, []);
  return (
    <div className="relative min-h-screen top-0 landing-page-wrap  bg-landing-neutral  pb-default">
      <Head>
        <title>markets.sh </title>
      </Head>
      {/* <div className="absolute top-0 right-0 overflow-hidden   z-10 w-1/2  h-full"></div> */}

      <SalesHero />
      <MainFeatures />
      {/* <Process /> */}
      <ChatBenefits />
      {/* <TechBenefits /> */}
      <Subhead />
      <div className="mb-10" />
      <FooterCta />
      <div className="mb-20" />
      <div className="px-5">
        <ResearchFaq
          questions={[
            {
              q: 'How Does This Work?',
              a: "It's quite simple! You provide your goals and preferences, and our system handles the rest, keeping you updated every step of the way."
            },
            {
              q: 'How Many Leads Can I Expect?',
              a: 'The number of leads can vary based on your industry and campaign settings, but we aim to maximize your potential with every campaign.'
            },
            {
              q: 'What Sources Do You Use?',
              a: 'We use a diverse range of sources including databases, social media insights, and market analysis to generate quality leads.'
            },
            {
              q: 'Does This Mean You Can Automate My Sales Process?',
              a: 'Absolutely! We can automate many aspects of your sales process, from lead generation to initial contact, making your sales efforts more efficient.'
            },
            {
              q: 'Do You Integrate With My CRM',
              a: 'Yes, we seamlessly integrate with most CRM systems, ensuring that your leads are directly funneled into your sales pipeline.'
            },
            {
              q: 'Can I Try Before I Buy?',
              a: 'Definitely! We offer a trial period so you can see the effectiveness of our system before making a commitment.'
            }
          ]}
        />
      </div>
    </div>
  );
}

New.getMainLayout = (page: ReactElement) => {
  // Todo: Make the header a nice gradient backdrop with the color of the stock?
  return <LandingLayout>{page}</LandingLayout>;
};

export default New;
