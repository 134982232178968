import React, { useState } from 'react';
import { ChevronRight } from 'lucide-react';
// Import the Message component
import Link from 'next/link';
import { MainLightHighlight } from '../utils';
import LandingButton from '@/components/ui/Button/LandingButton';
import SocialProof2 from './SocialProof2';
import { animated } from 'react-spring';
const data = [
  {
    title: 'Stop Researching, Start Closing',
    subtitle: `We’re transforming the business and investing world by integrating data intelligence, automation, and AI into a single platform anyone can use.`
  }
];

export default function LandingHero() {
  const [currentInteractionIndex, setCurrentInteractionIndex] = useState(0);
  const [messages, setMessages] = useState([]);
  const [title, setTitle] = useState(data[0].title);

  return (
    <div className="relative z-10">
      <MainLightHighlight />
      <div className="border border-line z-30 relative">
        <div className="content-default  h-screen z-20 relative max-w-7xl px-8">
          <div className="h-full flex flex-col  items-start justify-end md:justify-center pb-40 md:pb-20">
            <div className="pr-20 text-left">
              <div className="">
                <div className="  ">
                  <div className="bg-gradient-to-r from-green-200 via-green-300 to-blue-500 inline-block rounded-full mb-5">
                    <Link href="/platform">
                      <span className="bg-[#fafafa] dark:bg-[#0D0D0D] rounded-full  m-[2px] px-3 flex flex-row items-center ">
                        Discover The Platform
                        <ChevronRight size={16} className="mt-0.5" />
                      </span>
                    </Link>
                  </div>
                </div>
                <h2 className="text-3xl leading-tight md:text-[4.4rem] font-medium md:leading-[4.8rem] mb-1 md:mb-5 tracking-tighter text-transparent bg-clip-text bg-gradient-to-b from-neutral-700 to-black dark:from-neutral-200 dark:to-neutral-400 pb-2">
                  Intelligence
                  <br />
                  Working For You
                </h2>

                <div className="max-w-md">
                  <p className="mb-8 leading-normal text-sm md:text-lg text-primary tracking-tight ">
                    {data[currentInteractionIndex].subtitle}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex gap-x-2">
              <LandingButton href="/signup" title={'Get Started'} />
              <LandingButton
                href="/contact"
                title={'Get A Demo'}
                arrow={false}
                style="secondary"
              />
            </div>
          </div>
        </div>
      </div>

      <div className=" absolute bottom-0 left-0 z-20 w-full flex flex-col py-5 items-center  bg-gradient-to-b from-transparent via-main-background to-main-background ">
        <div className="w-full mb-4 hidden md:block pt-10 ">
          <SocialProof2 />
        </div>
        {/* <ChevronDown className="opacity-50" size={50} strokeWidth={1} /> */}
      </div>

      <div className="h-screen absolute top-0 left-0 z-10 w-full bg-[radial-gradient(ellipse_at_top_left,_var(--tw-gradient-stops))] from-transparent via-slate-100 dark:via-slate-900  to-transparent">
        <svg
          width="1922"
          height="1080"
          className="h-full top-0 left-0 w-full absolute"
          // viewBox="0 0 1922 1080"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M-798 1080C-798 1752.65 -233.688 2298 462.5 2298C1158.69 2298 1723 1752.65 1723 1080C1723 407.349 1158.69 -138 462.5 -138C-233.688 -138 -798 407.349 -798 1080Z"
            stroke="#01DD3D"
            strokeWidth="2"
          />
          <g filter="url(#filter0_f_17_39)">
            <path
              d="M-861 1080C-861 1752.65 -296.688 2298 399.5 2298C1095.76 2298 1723 1763.58 1723 1091C1723 418.349 1158.69 -127 462.5 -127C-233.765 -127 -861 407.421 -861 1080Z"
              stroke="#01DD3D"
              strokeWidth="2"
            />
          </g>
          <circle
            cx="1069.5"
            cy="1069.5"
            r="1068.5"
            transform="matrix(-1 0 0 1 1644 -1283)"
            stroke="#00F0FF"
            strokeWidth="2"
          />
          <g filter="url(#filter1_f_17_39)">
            <circle
              cx="1069.5"
              cy="1069.5"
              r="1068.5"
              transform="matrix(-1 0 0 1 1644 -1283)"
              stroke="#00F0FF"
              strokeWidth="2"
            />
          </g>
          <animated.circle
            cx="622"
            cy="622"
            r="621"
            transform="matrix(-1 0 0 1 2070 280)"
            stroke="#FFC700"
            strokeWidth="2"
            // style={animateCircle}
          />
          <g filter="url(#filter2_f_17_39)">
            <animated.circle
              cx="622"
              cy="622"
              r="621"
              transform="matrix(-1 0 0 1 2070 280)"
              stroke="#FFC700"
              strokeWidth="2"
              //   style={animateCircle}
            />
          </g>
          <defs>
            <filter
              id="filter0_f_17_39"
              x="-902"
              y="-168"
              width="2666"
              height="2507"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="20"
                result="effect1_foregroundBlur_17_39"
              />
            </filter>
            <filter
              id="filter1_f_17_39"
              x="-535"
              y="-1323"
              width="2219"
              height="2219"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="20"
                result="effect1_foregroundBlur_17_39"
              />
            </filter>
            <filter
              id="filter2_f_17_39"
              x="806"
              y="260"
              width="1284"
              height="1284"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="10"
                result="effect1_foregroundBlur_17_39"
              />
            </filter>
          </defs>
        </svg>
      </div>
    </div>
  );
}
