import React, { useState, useEffect, useRef } from 'react';
import { MessagesSquare, PackageCheck, Workflow } from 'lucide-react';
import { animated, useTrail } from 'react-spring';
import Message from '../Message';
import FeatureBlock from '../../utils/FeatureBlock';
import { TopGradientHighlight, TopHighlight } from '../../utils';
import blairImage from '@/public/img/marketing/chat/blair.png';
import Image from 'next/image';
import excel from '@/components/icons/Excel';
import { useDebouncedCallback } from 'use-debounce';
// Import the Message component

const data = [
  {
    title: 'Find Better Leads',
    messages: [
      {
        msg: 'Get a list of recently founded German startups, add add the founders and investor list from Handelsregister.',
        reply: false
      },
      {
        msg: `Of course. This task might take some time, i'll notify you once it is complete. `,
        reply: true
      },
      {
        wait: 3000
      },
      {
        msg: 'The task is complete. You can download the Excel sheet below.',
        reply: true
      },

      { filename: 'german_startups_list.xls', icon: excel, reply: true }
    ]
  },
  {
    title: 'Find Better Leads',
    messages: [
      {
        msg: 'Get a list of recently founded German startups, add add the founders and investor list from Handelsregister.',
        reply: false
      },
      {
        msg: `Of course. This task might take some time, i'll notify you once it is complete. `,
        reply: true
      },
      {
        wait: 3000
      },
      {
        msg: 'The task is complete. You can download the Excel sheet below.',
        reply: true
      },

      { filename: 'german_startups_list.xls', icon: excel, reply: true }
    ]
  }
];

export default function LandingHero() {
  const [currentInteractionIndex, setCurrentInteractionIndex] = useState(0);
  const [messages, setMessages] = useState<any[]>([]);
  const [title, setTitle] = useState(data[0].title);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   setTitle(data[currentInteractionIndex].title);
  //   setMessages([]);
  //   const interactionMessages = data[currentInteractionIndex].messages;

  //   interactionMessages.forEach((message, index) => {
  //     setTimeout(
  //       () => {
  //         setMessages((prevMessages) => [...prevMessages, message]);
  //       },
  //       (index + 1) * 2000 + (message.wait || 0)
  //     );
  //   });

  //   const cycleTimeout = setTimeout(
  //     () => {
  //       setCurrentInteractionIndex(
  //         (prevIndex) => (prevIndex + 1) % data.length
  //       );
  //     },
  //     (interactionMessages.length + 1) * 1200 + 3000
  //   );

  //   return () => clearTimeout(cycleTimeout);
  // }, [currentInteractionIndex]);

  useEffect(() => {
    setTitle(data[currentInteractionIndex].title);
    setMessages([]);
    const interactionMessages = data[currentInteractionIndex].messages;

    const showMessages = async () => {
      for (let index = 0; index < interactionMessages.length; index++) {
        const message = interactionMessages[index];
        setMessages((prevMessages) => [...prevMessages, message]);
        await new Promise(
          (resolve) => setTimeout(resolve, message.wait || 1000) // Default to 2000ms if no specific wait time is provided
        );
      }
      setTimeout(() => {
        setCurrentInteractionIndex(
          (prevIndex) => (prevIndex + 1) % data.length
        );
      }, 5000); // Wait 3 seconds before switching to the next interaction
    };

    showMessages();
  }, [currentInteractionIndex]);

  const trail = useTrail(messages.length, {
    from: { opacity: 0, transform: 'translateY(10px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    config: { duration: 300 }
  });

  const shouldAutoScroll = useRef(true);

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }
  };

  const debouncedScrollToBottomAndResize = useDebouncedCallback(() => {
    scrollToBottom();
  }, 50);

  useEffect(() => {
    debouncedScrollToBottomAndResize();
  }, [trail]);

  return (
    <div className="py-10 content-default">
      <div className="max-w-7xl mx-auto grid  grid-cols-1 md:grid-cols-2  gap-x-10">
        <div className="w-full pt-10 max-w-md mx-auto">
          <div className="relative overflow-hidden shadow-xl shadow-black/5 border border-line rounded-xl ">
            <TopHighlight />
            <TopGradientHighlight />
            <div className=" ">
              <div className="flex w-full py-3 bg-neutral-100 dark:bg-black px-5 gap-x-4 items-center  border-b border-line">
                <Image
                  src={blairImage}
                  alt=""
                  className="rounded-full  w-12 h-12 flex-shrink-0"
                />
                <div className="leading-tight py-2 flex-grow">
                  <h2 className="font-medium text-primary">Blair</h2>
                  <p>Ask Your Analyst</p>
                </div>
                <div>
                  <div className="bg-green-600 w-1.5 h-1.5 rounded-full animate-pulse"></div>
                </div>
              </div>
              <div
                className="relative overflow-y-scroll scrollbar-none px-5 pt-10 pb-20  h-72 "
                // style={{ height: '300px' }}
                ref={containerRef}
              >
                {/* <div className="w-full  p-4"> */}
                {trail.map((style, index) => (
                  <animated.div key={index} style={style}>
                    <Message
                      key={index}
                      item={messages[index]}
                      isLast={index == messages?.length - 1}
                    />
                  </animated.div>
                ))}
                <div
                  className="w-full"
                  ref={messagesEndRef}
                  style={{ height: 10 }}
                />
                {/* </div> */}
              </div>
              {/* <div className="absolute w-full  bottom-0 px-1">
                  <div className="absolute left-0 w-full bottom-0 h-20 bg-gradient-to-b from-transparent to-landing-neutral  pointer-events-none"></div>
                </div> */}
            </div>
          </div>
        </div>
        <div className="w-full pt-10 flex flex-col justify-center">
          <div>
            <h3 className="font-medium text-blue-500">Virtual Analyst </h3>
            <h2 className="text-3xl font-semibold leading-tight tracking-tighter text-primary sm:text-3xl">
              Delegate Research <br />
              And Data Collection
            </h2>
            <p className="mt-2 mb-5 opacity-80">
              Meet Blair, your personal analyst who can help you with a variety
              of tasks. Research companies, find people, search for financial
              reports and more. Blair is available 24/7 to help you with your
              research needs.
            </p>
            <FeatureBlock
              features={[
                { name: 'Chat Based', icon: MessagesSquare },
                { name: 'Excel Deliverables', icon: PackageCheck },
                { name: 'Workflows', icon: Workflow }
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
