import { BarChart4, List, Newspaper } from 'lucide-react';
import { TopHighlight, TopGradientHighlight } from '../../utils';
import FeatureBlock from '../../utils/FeatureBlock';
import SignalExample from '../../../Marketing/SignalExample';

export default function Subhead() {
  const isBrowser = typeof window !== 'undefined';
  return (
    <div className="relative ">
      <div className="py-10 content-default">
        <div className="mx-auto max-w-7xl  grid md:grid-cols-2 grid-cols-1">
          <div className=" w-full pt-10  ">
            <div>
              {/* <SmartListIcon /> */}
              <h3 className="font-medium text-orange-500">AI Signals</h3>
              <h2 className="text-3xl font-semibold leading-tight tracking-tighter text-primary sm:text-3xl">
                Always Have <br />
                The Perfect Timing
              </h2>
              <p className="mt-2 mb-8 opacity-80">
                Stop missing out on important events and opportunities. Markets
                signals will notify about important events you need to know
                about. Freely define your own signals and get notified when they
                are triggered.
              </p>
              <FeatureBlock
                features={[
                  { name: 'News Events', icon: Newspaper },
                  { name: 'New Financial Data', icon: BarChart4 },
                  { name: 'Funding Rounds', icon: List }
                ]}
              />
            </div>
          </div>
          <div className="  relative overflow-clip ">
            <TopHighlight />
            <TopGradientHighlight />
            <div className="border border-line rounded-lg pb-5 px-5 pt-5 h-full min-h-[10rem] max-w-md mx-auto">
              <SignalExample />
            </div>
            <div className="absolute w-full  bottom-0 px-1">
              <div className="absolute left-0 w-full bottom-0 h-20 bg-gradient-to-b from-transparent to-landing-neutral  pointer-events-none"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
