import { Radar, BadgeDollarSign, UserSearch, AlarmClock } from 'lucide-react';
import ChatBenefitsGrid from '../ChatBenefitsGrid';

export default function Features() {
  return (
    <div className="flex flex-col  landing-page pt-10  ">
      <div className="flex z-10 relative flex-col items-center  gap-y-10  pb-20 px-5 max-w-7xl mx-auto">
        <div className="flex flex-col items-center landing-page   px-5 ">
          <h2 className="text-2xl md:text-4xl font-semibold text-center max-w-lg tracking-tighter leading-tight mb-5">
            Automation That Makes
            <br /> A Difference
          </h2>
          <p className="text-center   md:text-md">
            We use autonomous AI to automate the repetitive, time intensive and
            boring parts of sales intelligence and investment research.
          </p>
        </div>
        <ChatBenefitsGrid
          features={[
            {
              title: 'Save Time',
              description: `Our Users save an average of 10 hours per week by cutting out the manual work of researching companies, industries and markets.`,
              icon: AlarmClock
            },
            {
              title: 'Improve Your Results',
              description: `Wheter you are a sales professional, investor or marketer, our AI-driven insights help you make better decisions and close more deals.`,
              icon: Radar
            },
            {
              title: 'Improve Your ROI',
              description: `Markets AI is a fraction of the cost of hiring a full-time analyst, and buying third-party software. You'll save money and get better results.`,
              icon: UserSearch
            },
            {
              title: 'Source More Deals',
              description: `Tap into new markets, discover new opportunities and find the best companies to invest in or sell to.`,
              icon: BadgeDollarSign
            }
          ]}
        />
      </div>
    </div>
  );
}
